export const FETCH_APPS_REQUEST = "FETCH_APPS_REQUEST";
export const FETCH_APPS_SUCCESS = "FETCH_APPS_SUCCESS";
export const FETCH_APP_CHANGE = "FETCH_APP_CHANGE";
export const ROLES_APP = {
  STAFF: "STAFF",
  OPERATOR: "OPERATOR",
  ACCOUNTANT: "ACCOUNTANT",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
  HDV: "HDV",
  NCC: "NCC",
  COMPANYADMIN: "COMPANYADMIN",
};

export const MANAGEMENT = {
  USER: "USER",
  TOUR: "TOUR",
  ORDER: "ORDER",
  PAYMENT: "PAYMENT",
  STATISTICAL: "STATISTICAL",
  OPERATING: "OPERATING",
  BOOK: "BOOK",
  MESSAGE: "MESSAGE",
  CATEGORY: "CATEGORY",
  VISABOOKING: "VISABOOKING",
  AIRTICKERBOOKING: "AIRTICKERBOOKING",
  SUPPLIERMATERIAL: "SUPPLIERMATERIAL",
  ORDERCUSTOMERLIST: "ORDERCUSTOMERLIST",
  MATERIALBALANCE: "MATERIALBALANCE",
  MATERIALTRANSACTION: "MATERIALTRANSACTION",
  MATERIALTRANSACTIONDETAIL: "MATERIALTRANSACTIONDETAIL",
  REPORT: "REPORT",
  HDV: "HDV",
};

export const ACTION = {
  CREATE: "C",
  VIEW: "R",
  UPDATE: "U",
};
