import React from "react";
import { ROLES_APP } from "../../../../../src/constants/app";
import { getUserData } from "../../../../../src/utility/Utils";

import { ReactComponent as Facebook } from "../../../../../src/@core/assets/icons/facebook_rounded.svg";
import { ReactComponent as Zalo } from "../../../../../src/@core/assets/icons/zalo-icon.svg";
import "./social.scss";

const SocialNetwork = () => {
  const userData = getUserData();

  if (userData?.role === ROLES_APP.CUSTOMER) {
    return (
      <div className="contact">
        <div className="item">
          <span className="zalo" title="Zalo">
            <a target={"_blank"} href="https://zalo.me/0903246045">
              <Zalo />
            </a>
          </span>
        </div>

        <div className="item">
          <span className="facebook" title="Facebook">
            <a target={"_blank"} href="https://www.facebook.com/c2itech">
              <Facebook />
            </a>
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default SocialNetwork;
